import { createRouter, createWebHistory } from 'vue-router';

import Projects from './views/Projects';
import Technologies from './views/Technologies';
import CareerSteps from './views/CareerSteps';
import Why from './views/Why';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/projects' },
    { path: '/projects', component: Projects },
    { path: '/technologies', component: Technologies },
    { path: '/career-steps', component: CareerSteps },
    { path: '/why', component: Why },
    { path: '/:notFound(.*)',  redirect: '/' }
  ]
});

export default router;
