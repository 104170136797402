<template>
  <div class="bg-white rounded-xl shadow-md border overflow-hidden">
    <div class="grid grid-cols-1 lg:grid-cols-2">
      <div class="border-r h-96 lg:h-full-project relative bg-gray-200">
        <div class="absolute-center">
          <div class="lds-circle"><div></div></div>
        </div>

        <swiper
          class="h-full"
          :slides-per-view="1"
          :space-between="0"
          :pagination="{ clickable: true }"
          :autoplay="{ delay: 3000, disableOnInteraction: false }"
          navigation
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          @init="onSwiperInit"
          :key="id"
        >
          <swiper-slide v-for="image in images" :key="image">
            <img
              class="mx-auto h-full object-scale-down"
              :src="require(`/assets/images/projects/${image}`)"
              :alt="title"
            />
          </swiper-slide>
        </swiper>
      </div>
      <a :href="link" target="_blank" :class="{ 'pointer-events-none': !link }">
        <div
          class="lg:flex lg:flex-col lg:flex-wrap relative px-5 pt-4 h-auto lg:h-full-project pb-4 lg:pb-0"
        >
          <p class="uppercase tracking-wide text-2xl font-bold">
            {{ title }}
          </p>
          <p class="uppercase tracking-wide font-medium text-gray-500">
            {{
              moment(startDate).locale('fr').format('MMM YYYY') +
              ' - ' +
              (endDate.length > 0
                ? moment(endDate).locale('fr').format('MMM YYYY')
                : 'EN COURS')
            }}
          </p>
          <div class="mt-4">
            <div
              v-for="technology in technologies"
              :key="technology"
              class="bg-purple-700 text-white font-semibold rounded-xl inline-block px-3 my-1 mr-2"
            >
              {{ technology }}
            </div>
          </div>
          <div class="flex-grow mt-5">
            <p class="text-purple-700">
              {{ description }}
            </p>
            <p class="text-gray-500 mt-2">
              {{ descriptionFull }}
            </p>
          </div>
        </div></a
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    id: String,
    title: String,
    technologies: Array,
    description: String,
    descriptionFull: String,
    images: Array,
    startDate: String,
    endDate: String,
    link: String,
  },
  setup() {
    function onSwiperInit() {
      console.log('swipper init !!!!!!!!!!');
    }
    return { moment, onSwiperInit };
  },
};
</script>