<template>
  <div
    :class="{ 'border-4 border-purple-700 -m-1': selected }"
    class="bg-white rounded-xl shadow-md box-content overflow-hidden up-animation"
  >
    <div class="flex flex-row flex-wrap md:flex-nowrap">
      <div class="flex-shrink-0 border-r-0 mx-auto md:border-r">
        <img
          class="object-cover object-left-top h-24 md:h-48 w-48"
          :src="require(`/assets/images/projects/${images[0]}`)"
          :alt="title"
        />
      </div>
      <div class="flex flex-col relative px-5 pt-4 h-36 md:h-48">
        <p class="uppercase tracking-wide text-sm leading-tight font-bold">
          {{ title }}
        </p>
        <p
          class="uppercase tracking-wide text-xxs font-medium text-gray-500 mt-1"
        >
          {{
            moment(startDate).locale('fr').format('MMM YYYY') +
            ' - ' +
            (endDate.length > 0
              ? moment(endDate).locale('fr').format('MMM YYYY')
              : 'EN COURS')
          }}
        </p>
        <div class="mt-1">
          <div
            v-for="technology in technologies"
            :key="technology"
            class="bg-purple-700 text-white font-semibold rounded-xl text-xs inline-block px-2 mr-2"
          >
            {{ technology }}
          </div>
        </div>
        <div class="flex-grow overflow-hidden">
          <p class="mt-2 text-xs font-medium text-gray-500">
            {{ description }} {{ descriptionFull }}
          </p>
        </div>
        <div
          class="absolute left-0 top-0 h-full w-full purple-bottom-shadow"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    id: String,
    title: String,
    technologies: Array,
    description: String,
    descriptionFull: String,
    images: Array,
    startDate: String,
    endDate: String,
    selected: Boolean,
  },
  setup() {
    return { moment };
  },
};
</script>