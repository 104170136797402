<template>
  <teleport to="body">
    <div
      v-if="show"
      @click="tryClose"
      class="bg-black bg-opacity-50 fixed left-0 top-0 w-full h-screen z-30"
    ></div>
    <transition name="dialog">
      <div class="fixed-center w-full z-50 px-3">
        <div
          open
          v-if="show"
          class="px-6 pt-4 pb-3 max-w-md mx-auto bg-white rounded-xl shadow-md items-center"
        >
          <slot></slot>
          <p @click="tryClose" class="text-gray-500 text-center text-sm mt-5 mx-auto cursor-pointer">Fermer</p>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(_, context) {
    function tryClose() {
      context.emit('close');
    }

    return { tryClose };
  },
};
</script>

<style scoped>
.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>