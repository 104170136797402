import { createApp } from 'vue';

import App from './App.vue';
import './styles.css';
import '@fortawesome/fontawesome-free/js/all.js';
import router from './router.js';
import store from './store/index.js';

import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import Project from './components/ui/Project.vue';
import FullProject from './components/ui/FullProject.vue';
import Technology from './components/ui/Technology.vue';
import CareerStep from './components/ui/CareerStep.vue';


const app = createApp(App);

app.use(router);
app.use(store);

app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('base-dialog', BaseDialog);
app.component('project', Project);
app.component('full-project', FullProject);
app.component('technology', Technology);
app.component('career-step', CareerStep);
app.mount('#app');
