<template>
  <a :href="link" target="_blank" :class="{ 'pointer-events-none': !link }">
    <div class="bg-white rounded-xl shadow-md box-content overflow-hidden" :class="{ 'up-animation': link }">
      <div class="flex flex-row flex-wrap md:flex-nowrap">
        <div class="flex-shrink-0 border-r-0 mx-auto md:border-r">
          <img
            class="object-cover object-left-top h-48 w-48"
            :src="require(`/assets/images/technologies/${image}`)"
            :alt="title"
          />
        </div>
        <div class="flex flex-col relative px-5 py-4 md:h-48">
          <p class="uppercase tracking-wide text-sm leading-tight font-bold">
            {{ title }}
          </p>
          <div class="mt-1">
            <div
              v-for="field in fields"
              :key="field"
              class="bg-purple-700 text-white font-semibold rounded-xl text-xs inline-block px-2 mr-2"
            >
              {{ field }}
            </div>
          </div>
          <div class="flex-grow overflow-hidden">
            <p
              v-html="description"
              class="mt-2 text-xs font-medium text-gray-500"
            ></p>
          </div>
        </div>
      </div></div
  ></a>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    fields: Array,
    description: String,
    image: String,
    link: String,
  },
  setup() {},
};
</script>