<template>
  <the-header></the-header>
  <hr class="py-3" />
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  <hr class="py-3" />
  <the-footer class="mt-auto"></the-footer>
  <base-dialog :show="showContactModal" @close="setContactModal(false)">
    <p class="text-center font-semibold text-purple-700 text-xl">
      Gagnons du temps !
    </p>
    <p class="font-medium text-gray-700 text-sm mt-4">
      Dans votre message, n'hésitez pas à apporter les précisions suivantes pour
      que je puisse répondre adéquatement :
    </p>
    <div class="text-sm text-purple-700 mt-2">
      <p><i class="fas fa-arrow-circle-right mr-2"></i>Votre structure</p>
      <p><i class="fas fa-arrow-circle-right mr-2"></i>Le type de projet</p>
      <p>
        <i class="fas fa-arrow-circle-right mr-2"></i>Vos exigences techniques
      </p>
      <p><i class="fas fa-arrow-circle-right mr-2"></i>Le temps imparti</p>
      <p><i class="fas fa-arrow-circle-right mr-2"></i>Votre budget</p>
    </div>
    <p class="font-medium text-gray-700 text-sm mt-2">
      Je réponds très rapidement, à tout de suite !
    </p>
    <a href="mailto:mdutres@mytip.co">
      <div
        class="font-semibold text-white text-center bg-purple-700 rounded-lg shadow-sm w-48 py-3 px-5 mx-auto mt-5 up-animation cursor-pointer"
      >
        <i class="far fa-envelope opacity-1 text-lg mr-3"></i>Cliquer ici
      </div></a
    >
  </base-dialog>
</template>

<script>
import { provide, ref } from 'vue';

export default {
  setup() {
    const showContactModal = ref(false);

    function setContactModal(val) {
      showContactModal.value = val;
    }

    provide('setContactModal', setContactModal);

    return {
      showContactModal,
      setContactModal,
    };
  },
};
</script>

<style>
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.1s ease-out;
}

.route-leave-active {
  transition: all 0.1s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>