<template>
  <div class="flex justify-center text-sm text-gray-500 space-x-4 py-2">
    <p @click="setContactModal(true)" class="cursor-pointer">Me contacter</p>
    <router-link target="_blank" to="//www.malt.fr/profile/maximedutres"
      ><p class="">Malt</p></router-link
    >
    <router-link target="_blank" to="//www.youtube.com/c/LesFr%C3%A8resCodeurs"
      ><p class="">YouTube</p></router-link
    >
    <router-link target="_blank" to="//twitter.com/FreresCodeurs"
      ><p class="">Twitter</p></router-link
    >
    <router-link target="_blank" to="//www.instagram.com/frerescodeurs/"
      ><p class="">Instagram</p></router-link
    >
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  setup() {
    const setContactModal = inject('setContactModal');
    return { setContactModal };
  },
};
</script>