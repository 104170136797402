import { createStore } from 'vuex';

import projectsModule from './modules/projects/index.js';
import technologiesModule from './modules/technologies/index.js';
import careerStepsModule from './modules/careerSteps/index.js';

const store = createStore({
  modules: {
    projects: projectsModule,
    technologies: technologiesModule,
    careerSteps: careerStepsModule
  }
});

export default store;