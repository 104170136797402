import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import careerStepsJSON from '/assets/careerSteps.json';

export default {
  namespaced: true,
  state() {
    return {
      careerSteps: careerStepsJSON
    };
  },
  mutations,
  actions,
  getters
};
