<template>
  <div
    class="bg-gradient-to-r from-purple-700 to-blue-700 px-5 md:px-20 relative z-0"
  >
    <div class="flex items-end">
      <div class="hidden xl:block flex-shrink pr-10">
        <img
          :src="require('/assets/images/max.png')"
          alt=""
          class="w-44 image-shadow pt-5"
        />
      </div>
      <div class="flex-grow py-5">
        <div
          class="flex flex-wrap border-b border-black border-opacity-10 pb-5"
        >
          <div class="flex-auto">
            <div class="flex flex-wrap sm:space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width="2rem"
                class="text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                />
              </svg>
              <p class="text-white font-semibold text-2xl ml-2">
                Maxime Dutrès
              </p>
              <p class="text-xl text-white font-semibold leading-8 opacity-75">
                Ingénieur et développeur fullstack web/mobile
              </p>
            </div>
          </div>
          <div class="flex-shrink text-white text-2xl mt-2 md:mt-0 space-x-5">
            <router-link
              target="_blank"
              to="//www.youtube.com/c/LesFr%C3%A8resCodeurs"
              ><i class="fab fa-youtube up-animation"></i
            ></router-link>
            <router-link target="_blank" to="//twitter.com/FreresCodeurs"
              ><i class="fab fa-twitter up-animation"></i
            ></router-link>
            <router-link target="_blank" to="//www.instagram.com/frerescodeurs/"
              ><i class="fab fa-instagram up-animation"></i
            ></router-link>
            <router-link target="_blank" to="//www.malt.fr/profile/maximedutres"
              ><i class="fas fa-briefcase up-animation"></i
            ></router-link>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-center">
          <div class="flex-auto pb-5 pt-5 md:pt-9">
            <p class="text-white font-semibold text-3xl mb-6">
              Je crée des applications web et mobiles scalables<br /><span
                class="opacity-75"
                >propulsées par les dernières technologies.</span
              >
            </p>
            <div class="flex space-x-6">
              <p class="text-white text-sm font-medium">
                <i class="fas fa-rocket opacity-50 mr-2"></i>Orienté startup
              </p>
              <p class="text-white text-sm font-medium">
                <i class="fas fa-book opacity-50 mr-2"></i>Suivi & documentation
              </p>
              <p class="text-white text-sm font-medium">
                <i class="fas fa-shield-alt opacity-50 mr-2"></i>Optimisé pour
                2021
              </p>
            </div>
          </div>
          <div class="flex-shrink text-center">
            <p class="text-white hidden lg:block text-sm mb-3">Je suis disponible !</p>
            <div
              @click="setContactModal(true)"
              class="flex items-center font-semibold text-purple-700 hover:text-purple-500 bg-white rounded-lg shadow-sm py-3 px-5 border border-transparent up-animation cursor-pointer"
            >
              <i class="far fa-envelope text-purple-400 text-lg mr-3"></i>
              <p>Me contacter</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white font-semibold shadow-lg sticky top-0 z-20 px-3 xl:px-20">
    <div
      class="grid grid-cols-4 items-center justify-around text-purple-700 text-sm md:text-xl py-3"
    >
      <router-link to="/projects"
        ><p class="text-center up-animation-soft">
          <span :class="isProjectPage ? 'border-b-2 border-purple-500' : ''">
            Projets
          </span>
        </p></router-link
      >
      <router-link to="/technologies"
        ><p class="text-center up-animation-soft">
          <span
            :class="isTechnologiesPage ? 'border-b-2 border-purple-500' : ''"
          >
            Technologies
          </span>
        </p></router-link
      >
      <router-link to="/career-steps"
        ><p class="text-center up-animation-soft">
          <span :class="isCareerPage ? 'border-b-2 border-purple-500' : ''">
            Parcours
          </span>
        </p></router-link
      >
      <router-link to="/why"
        ><p class="text-center  up-animation-soft text-xs sm:text-sm md:text-xl">
          <span :class="isWhyPage ? 'border-b-2 border-purple-500' : ''">
            Pourquoi moi ?
          </span>
        </p></router-link
      >
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const setContactModal = inject('setContactModal');

    const isProjectPage = computed(function () {
      return router.currentRoute.value.path === '/projects';
    });
    const isTechnologiesPage = computed(function () {
      return router.currentRoute.value.path === '/technologies';
    });
    const isCareerPage = computed(function () {
      return router.currentRoute.value.path === '/career-steps';
    });
    const isWhyPage = computed(function () {
      return router.currentRoute.value.path === '/why';
    });

    return {
      isProjectPage,
      isTechnologiesPage,
      isCareerPage,
      isWhyPage,
      setContactModal,
    };
  },
};
</script>