<template>
  <a :href="link" target="_blank" :class="{ 'pointer-events-none': !link }">
    <div
      class="bg-white text-center rounded-xl shadow-md box-content overflow-hidden p-5"
      :class="{ 'up-animation': link }"
    >
      <p class="uppercase tracking-wide text-sm leading-tight font-bold">
        {{ title }}
      </p>
      <p class="uppercase tracking-wide text-xs font-medium text-gray-500 mt-1">
        {{
          moment(startDate).locale('fr').format('MMM YYYY') +
          ' - ' +
          (endDate.length > 0
            ? moment(endDate).locale('fr').format('MMM YYYY')
            : 'EN COURS')
        }}
      </p>
      <p class="text-xs font-medium text-purple-700 mt-1">
        {{ (establishment.length > 0 ? establishment + ' - ' : '') + location }}
      </p>
      <div class="mt-2">
        <div
          v-for="technology in technologies"
          :key="technology"
          class="bg-purple-700 text-white font-semibold rounded-xl text-xs inline-block px-2 mr-2"
        >
          {{ technology }}
        </div>
      </div>
      <p
        v-html="description"
        class="mt-3 text-sm text-left font-medium text-gray-500"
      ></p></div
  ></a>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    id: String,
    title: String,
    location: String,
    establishment: String,
    technologies: Array,
    description: String,
    startDate: String,
    endDate: String,
    link: String,
  },
  setup() {
    return { moment };
  },
};
</script>