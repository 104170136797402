<template>
  <div class="px-2 md:px-10">
    <p class="font-semibold text-center text-lg text-gray-600">
      Voici mon parcours professionnel :
    </p>
    <div
      class="grid grid-cols-1 gap-2 justify-items-center md:gap-4 mt-5"
    >
      <career-step
      class="max-w-3xl"
        v-for="careerStep in careerSteps"
        v-bind="careerStep"
        :key="careerStep.id"
      ></career-step>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CareerStep from '../components/ui/CareerStep.vue';

export default {
  components: { CareerStep },
  setup() {
    const store = useStore();

    const careerSteps = computed(function () {
      return store.getters['careerSteps/careerSteps'];
    });

    return {
      careerSteps,
    };
  },
};
</script>

