import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import projectsJSON from '/assets/projects.json';

export default {
  namespaced: true,
  state() {
    return {
      projects: projectsJSON
    };
  },
  mutations,
  actions,
  getters
};
