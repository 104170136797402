<template>
  <div class="px-2 md:px-10" ref="topTitleElement">
    <p class="font-semibold text-center text-lg text-gray-600">
      Retrouvez ici mes principaux projets et réalisations :
    </p>
    <full-project
      v-if="selectedProject.length > 0"
      v-bind="getSelectedProject()"
      class="mt-5"
    ></full-project>
    <div
      class="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-2 md:gap-4 mt-5"
    >
      <project
        v-for="project in projects"
        v-bind="project"
        :selected="isSelectedProject(project.id)"
        :key="project.id"
        @click="selectProject(project.id)"
        class="cursor-pointer"
      ></project>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const selectedProject = ref('');
    const topTitleElement = ref(null);

    function selectProject(id) {
      // scroll
      setTimeout(
        () =>
          topTitleElement.value.scrollIntoView({
            behavior: 'smooth',
          }),
        100
      );
      selectedProject.value = id;
    }

    const projects = computed(function () {
      const projectsList = store.getters['projects/projects'];
      return projectsList.sort((a,b) => (new Date(a.startDate) > new Date(b.startDate)) ? -1 : ((new Date(b.startDate) > new Date(a.startDate)) ? 1 : 0));
    });

    function getSelectedProject() {
      const res = projects.value.find(
        (project) => project.id === selectedProject.value
      );
      return res;
    }

    function isSelectedProject(id) {
      return id === selectedProject.value;
    }

    return {
      projects,
      selectProject,
      selectedProject,
      getSelectedProject,
      isSelectedProject,
      topTitleElement,
    };
  },
};
</script>
