<template>
  <div class="px-2 md:px-10">
    <p class="font-semibold text-center text-lg text-gray-600">
      Retrouvez ici les technologies que j'utilise :
    </p>
    <div
      class="grid grid-cols-2 2xl:grid-cols-3 gap-2 md:gap-4 mt-5"
    >
      <technology
        v-for="technology in technologies"
        v-bind="technology"
        :key="technology.id"
      ></technology>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const technologies = computed(function () {
      return store.getters['technologies/technologies'];
    });

    return {
      technologies,
    };
  },
};
</script>

