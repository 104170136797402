<template>
  <div class="px-2 md:px-10">
    <p class="font-semibold text-center text-lg text-gray-600">
      Pourquoi travailler avec moi ?
    </p>
    <div class="grid grid-cols-1 justify-items-center">
      <div
        class="bg-white rounded-xl shadow-md box-content overflow-hidden max-w-3xl mt-5 p-5"
      >
        <p class="font-semibold text-purple-700 text-xl">
          Passez à la vitesse supérieure !
        </p>
        <p class="font-medium text-gray-700 text-sm mt-4">
          À travers mon parcours en startup et en tant qu'indépendant, j'ai fait
          face à nombre de situations complexes concernant des solutions
          informatiques. Je peux dire aujourd'hui que je maîtrise les tenants
          et aboutissants de tels projets, et vous garantit les aspects suivants
          quand nous collaborerons :
        </p>
        <div class="text-sm text-purple-700 mt-2">
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Professionnalisme</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Pragmatisme</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Écoute</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Honnêteté</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Flexibilité</p>
        </div>
        <p class="font-medium text-gray-700 text-sm mt-5">
          De plus, je travaille avec des technologies récentes et j'ai soigné
          mon expertise au sein de celles-ci, me permettant de développer des
          applications web et mobiles :
        </p>
        <div class="text-sm text-purple-700 mt-2">
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Scalables</p>
          <p>
            <i class="fas fa-arrow-circle-right mr-2"></i>Performantes
          </p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Modernes</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Robustes</p>
          <p><i class="fas fa-arrow-circle-right mr-2"></i>Élégantes</p>
        </div>
        <p class="font-medium text-gray-700 text-sm mt-5">
          Je suis passionné et je mettrai le maximum de mes compétences et
          capacités à votre service.
        </p>
        <div
          @click="setContactModal(true)"
          class="font-semibold text-white text-center bg-purple-700 rounded-lg shadow-sm w-48 py-3 px-5 mx-auto mt-5 up-animation cursor-pointer"
        >
          <i class="far fa-envelope opacity-1 text-lg mr-3"></i>Me contacter
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  setup() {
    const setContactModal = inject('setContactModal');

    return {
      setContactModal,
    };
  },
};
</script>

